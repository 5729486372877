import React from 'react';

import './loader.css'

class Loader extends React.Component {

  renderWord (instr) {
    var letters = (instr !== undefined) ? instr.split ("") : [];
    var retval = [];
    letters.forEach (
      (letter, ix) => {
        retval.push (<span className="loading-text-words" key={ ix }>{ letter }</span>);
      }
    );

    return retval;
  }

  render () {
    return (
      <React.Fragment>
        <div className="loading">
          <div className="loading-text">
            { this.renderWord ('LOADING') }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Loader;