import React from 'react';

// import Firebase, { FirebaseContext } from './context';

import Page from './page/page';

class App extends React.Component {
  render () {
    return (
      // <FirebaseContext.Provider value={ new Firebase () }>
        <Page />
      // </FirebaseContext.Provider>
    );
  }
}

export default App;
