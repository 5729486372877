import axios from "axios";

function axiosRequest (url) {

  let base = "https://corp.cdpc.org.my";

  return new Promise ((resolve, reject) => {
    let config = {
      url: base + url + "?sort=nid",
      headers: {
        Accept: "application/vnd.github.v3+json",
      }
    };
  
    axios
      .request (config)
      .then (
        response => {
          response.data.base = base;
          resolve (response.data);
        }
      )
      .catch (
        error => {
          reject (error);
        }
      );
    }
  );
}

export default axiosRequest;
