import React from 'react';

import PageContainer from '../pagecontainer';
import Loader from '../loader';

class PageLoading extends React.Component {

  renderPage () {

    return (
      <PageContainer bkgtop={true} bgblur={true}>
        <div className={`position-relative w-100 vh-100 d-flex flex-column align-items-stretch overflow-hidden`}>
          <div className={`position-relative z-0 bg-dark text-light flex-grow-1`}>
            <Loader />
          </div>
        </div>
      </PageContainer>
    );
  }

  render () {
    return this.renderPage ();
  }
}

export default PageLoading;
