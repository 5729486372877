import React from 'react';

import ContextSiteData from '../context/ContextSiteData';

import Header from './header';
import Copyright from './copyright';

class PageContainer extends React.Component {

  constructor (props) {
    super (props);

    let state = {};
    this.state = state;
  }

  componentDidMount () {
  }

  getStyleBackgroundImage (bkgimg) {
    let styleBackgroundImage = [];

    if (this.props.darktop === true) {
      styleBackgroundImage.push ('linear-gradient(to bottom, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 59%)');
    }
    else {
      styleBackgroundImage.push ('radial-gradient(ellipse at center, rgba(0,0,0,0) 50%,rgba(0,0,0,0.333) 100%)');
    }

    if (bkgimg) {
      styleBackgroundImage.push (`url(${bkgimg})`);
    }

    return { backgroundImage: styleBackgroundImage.join (',') };
  }
  
  render () {
    return (
      <ContextSiteData.Consumer>
        {
          (value) => {
            if (!value) { return null; }

            return (
              <div className={`position-relative w-100 vh-100 d-flex flex-column align-items-stretch overflow-hidden`}>
                <div 
                  className={`position-absolute z-0 w-100 vh-100 bkg-img ${this.props.bkgtop === true ? "bkg-img-top" : ""} ${this.props.bgblur === true ? "blur-02 scale-01" : ""}`}
                  style={ this.getStyleBackgroundImage (value.bkgimg) }
                ></div>
                <Header />
              
                <div
                  className={`content-area z-0 bkg-img flex-grow-1 overflow-auto d-flex flex-column align-items-stretch `}
                >
                  <div className="flex-grow-1 minheight-nav">
                    {/* this is just a spacer */}
                  </div>

                  <div className="flex-shrink-0 text-center minheight-content my-3">
                    { this.props.children }
                  </div>

                  <div className="minheight-5 flex-grow-2 d-flex align-items-end">
                    <Copyright />
                  </div>

                </div>
              </div>
            )
          }
        }
      </ContextSiteData.Consumer>
    );
  }
}

export default PageContainer;
