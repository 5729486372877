import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import * as ROUTES from './routes';
import Ichtys from './logo/ichtys';
import './header.css'

import ContextSiteData from '../context/ContextSiteData';

class Header extends React.Component {
  state = {
    open: false,
  }

  toggleMenu = () => {
    this.setState ({ open: !this.state.open, });
  }

  openMenu = () => {
    this.setState (
      {
        open: true,
      }
    );
  }

  closeMenu = () => {
    this.setState (
      {
        open: false,
      }
    );
  }

  render () {

    return (
      <ContextSiteData.Consumer>
        {
          (value) => {
            if (value.sitedata) {
              let sitedata = value.sitedata;
              return (
                <div className={ "z-1 nav-wrapper header-bg position-relative flex-shrink-0 text-theme " + ((this.state.open) ? "open" : "") }>
                  <div className="px-3 py-3">
                    <div className="d-md-flex align-items-center">
                      
                      <div className="flex-grow-1 d-flex align-items-center d-md-block">
                        <div className="flex-grow-1">
                          <Link to={ ROUTES.WELCOME }>
                            <Ichtys displayname={ sitedata.displayname } logo={ sitedata.logo } />
                          </Link>
                        </div>
                        <div className="flex-grow-0 d-md-none">
                          <button className="nav-btn btn btn-sm btn-outline-theme" onClick={ this.toggleMenu }>
                            <span className="label-btn expand fas fa-bars"></span>
                            <span className="label-btn dismiss fas fa-times"></span>
                          </button>
                        </div>
                      </div>

                      <div className="flex-shrink-0">
                        <ul className="nav-ul list-unstyled mt-3 mt-md-0 mb-0 text-md-right font-weight-bold text-uppercase">
                          <li className="nav-li d-md-inline-block mt-2 mt-md-0 mx-md-3">
                            <NavLink 
                              exact
                              className="d-block text-color-inherit text-hover-white text-active-white" 
                              activeClassName="active"
                              to={ ROUTES.WELCOME } 
                              onClick={ this.closeMenu }
                            >Welcome</NavLink>
                          </li>
                          <li className="nav-li d-md-inline-block mt-2 mt-md-0 mx-md-3">
                            <NavLink 
                              className="d-block text-color-inherit text-hover-white text-active-white" 
                              activeClassName="active"
                              to={ ROUTES.CHURCHES }
                              onClick={ this.closeMenu }
                            >Churches</NavLink>
                          </li>
                          <li className="nav-li d-md-inline-block mt-2 mt-md-0 mx-md-3">
                            <NavLink 
                              className="d-block text-color-inherit text-hover-white text-active-white" 
                              activeClassName="active"
                              to={ ROUTES.STAFF }
                              onClick={ this.closeMenu }
                            >Staff</NavLink>
                          </li>
                          <li className="nav-li d-md-inline-block mt-2 mt-md-0 mx-md-3">
                            <NavLink 
                              className="d-block text-color-inherit text-hover-white text-active-white" 
                              activeClassName="active"
                              to={ ROUTES.OUTREACH }
                              onClick={ this.closeMenu }
                            >Outreach</NavLink>
                          </li>
                          <li className="nav-li d-md-inline-block mt-2 mt-md-0 mx-md-3">
                            <NavLink 
                              className="d-block text-color-inherit text-hover-white text-active-white" 
                              activeClassName="active"
                              to={ ROUTES.CONTACT }
                              onClick={ this.closeMenu }
                            >Contact</NavLink>
                          </li>
                        </ul>
                      </div>
                      
                    </div>
                  </div>
                </div>
              );
            }
            else {
              return null;
            }
          }
        }
      </ContextSiteData.Consumer>
    );
  }
}

export default Header;