import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ContextSiteData from '../context/ContextSiteData';
import axiosRequest from '../context/axios/axiosRequest';
import * as ROUTES from './routes';

import Loader from './loader';
import PageLoading from './content/loading';
import AddToHome from './content/add2home';

const Welcome = lazy (() => { return import ('./content/welcome'); });
const PageChurches = lazy (() => { return import ('./content/churches'); });
const PagePastors = lazy (() => { return import ('./content/pastors'); });
const PageOutreach = lazy (() => { return import ('./content/outreach'); });
const PageContact = lazy (() => { return import ('./content/contact'); });
const PageSystem = lazy (() => { return import ('./content/system'); });

const bgimgs = [
  "/img/kl_daynight_2.jpg",
  "/img/kl_daynight_3.jpg",
  "/img/klct2020.jpg",
  "/img/pjmidtown.jpg",
];

class Page extends React.Component {

  constructor (props) {
    super (props);

    this.state = {
      loading: false,
      sitedata: undefined,
      routes: undefined,
      bkgimg: this.getBgImg (),
    };
  }

  getBgImg () {
    let index = (Math.floor (Math.random () * bgimgs.length));
    return bgimgs[index];
  }

  componentDidMount () {
    this.getDataCorp ();
    this.getDataRedirects ();
  }

  parseSiteLogo (id, response) {

    let result = null;

    response.included.forEach (
      (item) => {
        if (item.id === id) {
          result = response.base + item.attributes.uri.url;
          return false;
        }
      }
    );

    return result;
  }

  parseSiteData (response) {

    let data = response.data[0];

    let sitedata = {
      address: data.attributes.field_address,
      copyright: data.attributes.field_copyright_notice.value,
      displayname: data.attributes.field_title,
      email: data.attributes.field_email[0],
      contactlink: data.attributes.field_url.uri,
      fullname: data.attributes.title,
      iframe: data.attributes.field_embed[0],
      motto: data.attributes.field_motto,
      phone: data.attributes.field_phone,
      mob: data.attributes.field_mobile_phone,
      heading: data.attributes.body.processed,
    };

    if (data.relationships.field_image && response.included) {
      sitedata.logo = this.parseSiteLogo (data.relationships.field_image.data.id, response);
    }
console.log (sitedata);
    this.setState (
      {
        sitedata: sitedata,
      }
    );
  }

  getDataCorp () {

    axiosRequest ("/json/node/detail")
      .then ((response) => { this.parseSiteData (response); })
      .catch ((err) => { console.error (err); }
    );

  }

  parseRedirect (response) {
    let routes = [];
    response.data.forEach (
      (item) => {
        if (item.attributes.status === true && item.attributes.field_short_link && item.attributes.field_url) {
          routes.push (
            {
              title: item.attributes.title || "",
              link: item.attributes.field_short_link,
              url: item.attributes.field_url.uri,
            }
          );
        }
      }
    );

    this.setState (
      {
        routes: routes,
      }
    );

  }

  getDataRedirects () {
    axiosRequest ("/json/node/redirect")
      .then ((response) => { this.parseRedirect (response); })
      .catch ((err) => { console.error (err); })
  }

  renderLinks () {
    let routes = [];

    if (this.state.routes) {
      this.state.routes.forEach (
        (route, index) => {
          routes.push(
            <Route
              key={index}
              exact
              path={`/${route.link}`}
              render={ 
                (props) => { 
                  return (
                    <PageSystem 
                      {...props} 
                      type={ 301 } 
                      title={ route.title }
                      url={ route.url} 
                    />
                  );
                }
              }
            />
          );
        }
      );
    }
    
    return routes;
  }

  renderRoutes () {

    return (
      <ContextSiteData.Provider 
        value={
          { 
            sitedata: this.state.sitedata, 
            bkgimg: this.state.bkgimg, 
          }
        }
      >

        <Switch>

          <Route 
            exact 
            path={ ROUTES.WELCOME } 
            render={ 
              (props) => {
                return (
                  <Welcome 
                    { ...props } 
                  />
                );
              }
            } 
          />

          <Route 
            exact 
            path={ ROUTES.CHURCHES } 
            render={ (props) => 
              <PageChurches 
                { ...props } 
              /> 
            } 
          />

          <Route 
            exact 
            path={ ROUTES.STAFF + '/:selectedpastor?' } 
            render={ (props) => 
              <PagePastors 
                { ...props } 
              /> 
            } 
          />

          <Route 
            exact 
            path={ ROUTES.OUTREACH } 
            render={ (props) => 
              <PageOutreach 
                { ...props } 
              /> 
            } 
          />

          <Route 
            exact 
            path={ ROUTES.CONTACT } 
            render={ (props) => 
              <PageContact 
                { ...props } 
              /> 
            } 
          />

          { this.renderLinks () }

          <Route 
            render={
              (props) => {
                return (
                  <PageSystem 
                    type={404} {...props} 
                    title={'404 : Page not found'} 
                    content={
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="p-4 rounded shadow bg-white-50">
                          <h4>We do not have any pages at this address.</h4>
                          <p>Please check to ensure you have the correct link and try again.</p>
                        </div>
                      </div>
                    }
                  />
                );
              }
            } 
          />
        
        </Switch>

      </ContextSiteData.Provider>
    );
  }

  renderLoading () {
    return (
      <div className={`position-relative w-100 vh-100 d-flex flex-column align-items-stretch overflow-hidden`}>
        <div className="position-relative z-0 bg-dark text-light flex-grow-1">
          <Loader />
        </div>
      </div>
    );
  }

  render () {
    if (!this.state.sitedata) {
      return this.renderLoading ();
    }

    return (
      <React.Fragment>
        <Router>
          <Suspense fallback={ <PageLoading /> }>
            { this.renderRoutes () }
          </Suspense>
        </Router>

        { AddToHome () }
      </React.Fragment>
    );
  }
}

export default Page;
