import React from 'react';

import ContextSiteData from '../context/ContextSiteData';

class Copyright extends React.Component {

  render () {
    return (
      <ContextSiteData.Consumer>
        {
          (value) => {
            if (value) {
              let sitedata = value.sitedata;
              return (
                <div className="my-3 w-100 px-3 text-center">
                  <small className="text-white-07 text-glow-black" dangerouslySetInnerHTML={{__html:sitedata.copyright }}></small>
                </div>
              );
            }
          }
        }
      </ContextSiteData.Consumer>
    );
  }

}
export default Copyright;
