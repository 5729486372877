import React from 'react';

import './ichtys.css';

class Ichtys extends React.Component {
  render () {
    const displayname = this.props.displayname || "";

    return (
      <div title="City Discipleship Presbyterian Church">
        <img className="site-logo d-inline-block align-middle" alt="Ichtys" src={ this.props.logo } />
        <h4 className="d-inline-block align-middle my-0 ml-2 text-theme">{ displayname }</h4>
      </div>
    );
  }
}

export default Ichtys;