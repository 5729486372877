const AddToHome = (props) => {

  const onClickPrompt = () => {
    if (window.deferredPrompt) {
      console.log ("inside window.deferredPromp if condition");
      window.deferredPrompt.prompt ();
      window.deferredPrompt.userChoice.then (
        (choiceResult) => {
          if (choiceResult.outcome === "accepted") { 
            console.log ("User accepted the install prompt");
          }
        }
      );
    }
  }

  const onClickY = () => {
    onClickPrompt ();
    hidePrompt ();
  }

  const onClickN = () => {
    hidePrompt ();
  }
  
  const hidePrompt = () => {
    document.getElementById (`pwa-prompt`).classList.add ("d-none");
  }

  const showPrompt = (e) => {
    e.preventDefault();
    window.deferredPrompt = e;
    document.getElementById (`pwa-prompt`).classList.remove ("d-none");
  }

  const listenForInstallPrompt = () => {

    console.log (`listenForInstallPrompt`);

    window.addEventListener (
      "beforeinstallprompt", 
      showPrompt
    );
  }

  listenForInstallPrompt ();

  return (
    <div id={`pwa-prompt`} className={`position-absolute x-100 y-100 d-none`}>
      <div className={`m-2 m-md-3 py-2 px-3 rounded bg-white-59 bg-filter-blur shadow font-size-dec`}>
        <div className={`mb-2`}>Add CDPC to your Home Screen?</div>
        <div className={`d-flex justify-content-end mx-n2 mb-2`}>
          <div className={`px-2`}>
            <button 
              className={`border-0 py-1 px-2 bg-theme`}
              onClick={ onClickY }
            >
              <span>Yes, sure</span>
            </button>
          </div>
          <div className={`px-2`}>
            <button 
              className={`border-0 py-1 px-2`}
              onClick={ onClickN }
            >
              <span>No thanks</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddToHome;
